<template>
  <div class="exit_phone">
    <back-step
      :currentTxt="`${$t('home.personal.editPhone.xgsj')}`"
      :goList="[
        {
          url: '/',
          name: `${$t('home.personal.personal.zy')}`
        },
        {
          url: '/home/personal',
          name: `${$t('home.personal.personal.grzx')}`
        }
      ]"
    />
    <div v-if="!isExitOk" class="children_con">
      <h5 class="c_con_title">{{ $t("home.personal.editPhone.xgsj") }}</h5>
      <ul class="c_form">
        <li class="f_single">
          <div class="s_label">{{ $t("home.personal.editPhone.sjh") }}：</div>
          <div class="iup_box">
            <input v-model="exitConfig.oldMobile" disabled type="number" />
          </div>
        </li>
        <li class="f_single">
          <div class="s_label">{{ $t("home.personal.editPhone.yzm") }}：</div>
          <div class="iup_box">
            <input
              v-model="exitConfig.oldSmsCaptcha"
              :placeholder="$t('home.personal.bandEmail.hqyzm')"
              type="number"
            />
            <span
              :class="isYzm ? 'yzm_sp_active' : ''"
              class="yzm_sp"
              @click="getYzm('0')"
              >{{
                isYzm
                  ? `${$t("home.personal.bandEmail.cxhq")} ${yzmTime}s`
                  : `${$t("home.personal.bandEmail.hqyzm")}`
              }}</span
            >
          </div>
        </li>
        <li class="f_single">
          <span class="s_label"></span>
          <div class="iup_box tip_box">
            {{ $t("home.personal.editPhone.sbdyzm") }}
            <el-tooltip :content="glyTxt" effect="light" placement="right">
              <span class="lx_kf">{{
                $t("home.personal.editPhone.lxkf")
              }}</span>
            </el-tooltip>
          </div>
        </li>
        <li class="f_single">
          <span class="s_label"
            >{{ $t("home.personal.editPhone.xsjh") }}：</span
          >
          <div class="iup_box">
            <input
              v-model="exitConfig.newMobile"
              :placeholder="$t('login.retrievePaw.srsjh')"
              type="number"
            />
          </div>
        </li>
        <li class="f_single">
          <span class="s_label">{{ $t("home.personal.editPhone.yzm") }}：</span>
          <div class="iup_box">
            <input
              v-model="exitConfig.newSmsCaptcha"
              :placeholder="$t('home.personal.bandEmail.hqyzm')"
              type="number"
            />
            <span
              :class="isYzm01 ? 'yzm_sp_active' : ''"
              class="yzm_sp"
              @click="getYzm('1')"
              >{{
                isYzm01
                  ? `${$t("home.personal.bandEmail.cxhq")} ${yzmTime01}s`
                  : `${$t("home.personal.bandEmail.hqyzm")}`
              }}</span
            >
          </div>
        </li>
      </ul>
      <div class="c_foo">
        <div class="cancel_btn flex-x-y-c curp" @click="goBack">
          {{ $t("common.message.qx") }}
        </div>
        <div
          class="confirm_btn flex-x-y-c curp main_theme_color_333"
          @click="submitForm"
        >
          {{ $t("common.message.qr") }}
        </div>
      </div>
    </div>
    <exit-success
      v-else
      :isExitOk="isExitOk"
      :successTxt="$t('common.message.sjhxgcg')"
    />
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
import BackStep from "../../../cases/cases-detail/childrenPublic/backStep";
import ExitSuccess from "./exitSuccess";
import FullLoading from "components/full-loading/full-loading";
import { modifyMobile, sendCaptcha } from "common/api/user";
import { notifyMsg, getUserId } from "common/js/util";
import { getSystormKey } from "common/api/public";
import { mapState } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      isExitOk: false,
      isYzm: false,
      yzmTime: 59,
      interTime: null,
      isYzm01: false,
      yzmTime01: 59,
      interTime01: null,
      exitConfig: {
        oldMobile: sessionStorage.getItem("userMobile") || "",
        newMobile: "",
        newSmsCaptcha: "",
        oldSmsCaptcha: ""
      },
      glyTxt: ""
    };
  },
  created() {
    getSystormKey("customer_service").then(data => {
      this.glyTxt =
        this.$t("home.personal.editPhone.lxkf") + ": " + data[0].value;
    });
  },
  methods: {
    getYzm(type) {
      const isYzmName = type === "0" ? "isYzm" : "isYzm01";
      const yzmTimeName = type === "0" ? "yzmTime" : "yzmTime01";
      const interTimeName = type === "0" ? "interTime" : "interTime01";
      if (this[isYzmName]) {
        return false;
      }
      const mobile = type === "0" ? "oldMobile" : "newMobile";
      if (
        !this.exitConfig[mobile] ||
        !/^1[3|4|5|6|7|8|9]\d{9}$/.test(this.exitConfig[mobile])
      ) {
        return notifyMsg(this, "warning", this.$t("common.message.qtxzqsj"));
      }
      if (
        type === "1" &&
        this.exitConfig.newMobile === this.exitConfig.oldMobile
      ) {
        return notifyMsg(this, "warning", this.$t("common.message.bnbdxtsjh"));
      }
      if (this[interTimeName]) {
        clearInterval(this[interTimeName]);
      }
      this.isLoading = true;
      sendCaptcha({
        mobile: this.exitConfig[mobile],
        bizType: "MODIFY_MOBILE"
      })
        .then(() => {
          this.isLoading = false;
          this[isYzmName] = true;
          this[interTimeName] = setInterval(() => {
            if (this[yzmTimeName] < 1) {
              this[isYzmName] = false;
              this[yzmTimeName] = 59;
              clearInterval(this[interTimeName]);
            }
            this[yzmTimeName]--;
          }, 1000);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    submitForm() {
      const {
        oldMobile,
        newMobile,
        newSmsCaptcha,
        oldSmsCaptcha
      } = this.exitConfig;
      const visMobile = /^1[3|4|5|6|7|8|9]\d{9}$/;
      if (!oldMobile) {
        return notifyMsg(this, "warning", this.$t("common.message.sjhbnk"));
      } else if (!newMobile || !newSmsCaptcha || !oldSmsCaptcha) {
        return notifyMsg(this, "warning", this.$t("common.message.qtxwz"));
      } else if (!visMobile.test(newMobile)) {
        return notifyMsg(this, "warning", this.$t("common.message.qzqtxsjh"));
      }
      this.isLoading = true;
      modifyMobile({
        id: getUserId(),
        oldMobile,
        newMobile,
        newSmsCaptcha,
        oldSmsCaptcha
      })
        .then(() => {
          this.isLoading = false;
          this.isExitOk = true;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    goBack() {
      window.history.go(-1);
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  components: {
    BackStep,
    ExitSuccess,
    FullLoading
  },
  beforeDestroy() {
    if (this.interTime) {
      clearInterval(this.interTime);
    }
    if (this.interTime01) {
      clearInterval(this.interTime01);
    }
  }
};
</script>

<style lang="scss" scoped>
.exit_phone {
  width: 13.44rem;
  margin: 0 auto;
  padding-top: 40px;

  .children_con {
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    .c_con_title {
      font-size: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 0.6rem;
      color: $main_theme_color_333;
      border-bottom: 1px solid #e5e5e5;
    }

    .c_form {
      width: 5.44rem;
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;

      .f_single {
        display: flex;
        align-items: center;
        margin-bottom: 0.4rem;

        .s_label {
          width: 0.8rem;
          margin-right: 0.1rem;
          white-space: nowrap;
          display: flex;
          flex-flow: row-reverse;
          font-size: 16px;
          color: $main_theme_color_333;
        }

        .iup_box {
          flex: 1;
          display: flex;
          align-items: center;
          position: relative;
          height: 0.52rem !important;

          input {
            flex: 1;
            border-radius: 4px;
            border: 0.01rem solid #bbbbbb;
            padding: 16px 20px;
            font-size: 14px;
            color: $main_theme_color_333;
          }

          .yzm_sp {
            margin-left: 0.1rem;
            width: 1.2rem;
            height: 0.4rem;
            border-left: 1px solid #e5e5e5;
            color: $main_theme_color;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            cursor: pointer;
            font-size: 14px;
          }

          .yzm_sp_active {
            color: $main_theme_color_666;
            cursor: default;
          }
        }

        .tip_box {
          font-size: 0.14rem;
          color: $main_theme_color_333;

          .lx_kf {
            color: #ed4027;
            cursor: pointer;
          }
        }
      }

      li:nth-child(2) {
        margin-bottom: 4px !important;
      }

      li:nth-child(3) {
        margin-bottom: 16px !important;
        height: 14px;
      }
    }
  }

  .c_foo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.6rem;

    .cancel_btn {
      width: 176px;
      height: 42px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #bbbbbb;
      font-size: 16px;
      color: $main_theme_color_333;
    }

    .confirm_btn {
      width: 176px;
      height: 42px;
      background: $main_theme_color;
      border-radius: 6px;
      font-size: 16px;
      margin-left: 32px;
      color: #fff !important;
    }
  }
}
</style>
